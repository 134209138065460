<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('teamReport.default[0]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <div v-for="(item,index) in listData" style="margin-left: 10px;background-color: #ffffff;padding: 7px;margin-right: 10px">
        {{item['username']}}
      </div>
      <div v-if="listData.length==0" style="margin: 20px auto;margin-left: 20px">{{$t('vanPull[1]')}}</div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Service',
    components: {

    },
    props: {},
    data() {
      return {
        listData:[],
      };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
      this.getListData();
    },
    activated() {

    },
    destroyed() {

    },
    methods: {
      getListData(type) {
        var json = {};
        this.$Model.TeamReport(json,(data) => {
          this.isLoad = false;
          if (data.code == 1) {
            this.listData = data.data;
          } else {
            this.listData = [];
            this.isFinished = true;
          }
        })
      }
    },
  }
</script>
<style scoped>
  .PageBox {
    color: #d7d7d7;
    padding: 0;
    background-color: #13171A;
  }

  .van-nav-bar {
    background-color: #191C23;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#fff;
    font-weight: bold;
  }
  .van-nav-bar>>>.van-nav-bar__left .van-icon{
  }
  .ScrollBox{
    color:#fff;
    margin-top: 40px;
    background-color: #13171A;
  }
</style>